import { AuthService } from 'auth';
import { filter, Subject, switchMap, takeUntil, tap } from 'rxjs';

import { SocialAuthService } from '@abacritt/angularx-social-login';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ModalComponent } from '../../../../share/components/modal/modal.component';
import { LoggerService } from '../../../../share/services/logger.service';
import { ToastService } from '../../../../share/services/toast.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  authForm: FormGroup = new FormGroup({
    login: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
  });
  passwordForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.email, Validators.required]),
  });
  isPasswordForgotten = false;
  destroy$ = new Subject<void>(); // for unit tests, not to let subscriptions running
  public favorite?: string;
  public inputType = 'password';
  public error?: string;
  public readonly WRONG_PASSWORD: string = 'Wrong password';
  public readonly USER_NOT_FOUND: string = 'User not found';

  constructor(
    private auth: AuthService,
    private router: Router,
    public toastService: ToastService,
    private socialAuthService: SocialAuthService,
    private loggerService: LoggerService,
    private modalService: NgbModal
  ) { }

  get login() {
    return this.authForm.get('login');
  }
  get password() {
    return this.authForm.get('password');
  }

  resetError() {
    this.error = undefined;
  }

  async loginWithSso() {
    try {
      const login = await this.auth.initialize('LOGIN');
      // initialize returns a login if a token have been refreshed from a previous token saved in localstorage
      if (login) {
        localStorage.setItem('user-name', login.prenom + ' ' + login.nom);
        localStorage.setItem('login', login.login);
        this.loggerService.log({
          event: 'connexion_sso',
          page: 'login_connexion',
          description: 'Se connecter via SSO user: ' + login.login,
        });
        this.favorite = localStorage.getItem(
          login.login + '-favorite'
        ) as string;
        this.redirectTo();
      } else {
        // we must get a new token, either from login/password or from socialAuthService

        this.socialAuthService.authState
          .pipe(
            takeUntil(this.destroy$),
            filter((socialUser) => socialUser != null),
            tap((socialUser) =>
              localStorage.setItem('avatar', socialUser?.photoUrl)
            ),
            switchMap((socialUser) =>
              this.auth.ssoLogin(socialUser.idToken)
            )
          )
          .subscribe({
            next: (login) => {
              localStorage.setItem('login', login.login);
              localStorage.setItem(
                'user-name',
                login.prenom + ' ' + login.nom
              );
              this.loggerService.log({
                event: 'Connexion SSO',
                page: 'login',
                description: 'Connexion SSO user: ' + login.login,
              });
              this.redirectTo();
            },
            error: (err) => {
              console.error('ssoLogin error', err);
              const modalRef = this.modalService.open(ModalComponent);
              modalRef.componentInstance.type = 'danger';
              modalRef.componentInstance.header = 'Erreur lors de la connexion';
              modalRef.componentInstance.message = err.error?.message ?? 'Erreur iconnue';
            },
          });
      }
    } catch (err: any) {
      const modalRef = this.modalService.open(ModalComponent);
      modalRef.componentInstance.type = 'danger';
      modalRef.componentInstance.header = 'Erreur lors de la connexion';
      modalRef.componentInstance.message = err.error.message;
    };
  }

  ngOnInit(): void {
    const login = localStorage.getItem('login');
    this.favorite = localStorage.getItem(login + '-favorite') as string;
    this.authForm.setValue({ login, password: '' });

    this.loginWithSso();
  }

  onLogin() {
    //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const login = this.login!.value!; // NOSONAR well ?? breaks tests coverage and login!.value! not null is asserted by Validator.required
    localStorage.setItem('login', login);
    //eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const password = this.password!.value!; // NOSONAR
    this.auth.passwordLogin(login, password).subscribe({
      next: (login) => {
        this.loggerService.log({
          event: 'connexion_login',
          page: 'login_connexion',
          description: 'Se connecter avec login/mot de passe: ' + login.login,
        });
        localStorage.setItem('user-name', login.prenom + ' ' + login.nom);
        this.favorite = localStorage.getItem(
          login.login + '-favorite'
        ) as string;
        this.redirectTo();
      },
      error: (err) => {
        console.error('passwordLogin error', err);
        this.error = undefined;
        if (err.status === 400) {
          this.error = this.WRONG_PASSWORD;
        }
        if (err.status === 404) {
          this.error = this.USER_NOT_FOUND;
        }
        if (!this.error) {
          const modalRef = this.modalService.open(ModalComponent);
          modalRef.componentInstance.type = 'danger';
          modalRef.componentInstance.header = 'Erreur lors de la connexion';
          modalRef.componentInstance.message = err.error.message;
        }
      },
    });
  }

  /* istanbul ignore next */
  private redirectTo() {
    if (this.auth.loginFrom) {
      window.location.href = `/${this.auth.loginFrom}`;
      this.auth.loginFrom = undefined;
    } else if (this.favorite) {
      window.location.href = `/${this.favorite}`;
    } else {
      window.location.href = `/accueil/`;
    }
    this.router.ngOnDestroy(); // kékécé ?
  }

  togglePassword() {
    this.inputType = this.inputType === 'password' ? 'text' : 'password';
  }

  passwordForgotten(event: boolean) {
    this.isPasswordForgotten = event;
  }

  resetPassword() {
    this.auth.resetPassword(this.passwordForm.value.email).subscribe({
      next: () => {
        this.loggerService.log({
          event: 'reset_password',
          description: 'Demander la réinitialisation mot de passe',
          page: 'login_connexion',
        });
        const modalRef = this.modalService.open(ModalComponent);
        modalRef.componentInstance.type = 'success';
        modalRef.componentInstance.header = 'Réinitialisation mot de passe';
        modalRef.componentInstance.message =
          'Un email de réinitialisation de mot de passe vous a été envoyé. <br> Consultez votre boite mail.';
      },
      error: (err) => {
        const modalRef = this.modalService.open(ModalComponent);
        modalRef.componentInstance.type = 'danger';
        modalRef.componentInstance.header = 'Réinitialisation mot de passe';
        modalRef.componentInstance.message = err.error
          ? err.error.message
          : 'Il y a eu une erreur lors de la demande de réinitialisation de votre mot de passe. <br> Veuillez contacter un administrateur. <br> Désolé pour la gêne occasionnée.';
      },
    });
  }
}
