<div class="rounded-1 border border-{{ type }}">
  <div class="modal-header justify-content-start title p-2 border-0">
    @if (type === 'success') {<i-bs name="check-circle-fill" class="m-2 mt-0 text-success"></i-bs>}
    @if (type === 'warning') {<i-bs name="exclamation-triangle-fill" class="m-2 mt-0 text-warning"></i-bs>}
    @if (type === 'danger') {<i-bs name="exclamation-circle-fill" class="m-2 mt-0  text-danger"></i-bs>}
    @if (type === 'info') { <i-bs name="info-circle-fill" class="m-2 mt-0 text-info"></i-bs>}
   
    <div class="border-bottom border-3 ms-2 pb-1 border-{{type}} title">
      <h4 class="modal-title title">
        {{ header }}
      </h4>
    </div>
  </div>
  <div class="modal-body my-2 mx-4">
    <p [innerHtml]="message"></p>
  </div>
  <div class="modal-footer border-0">
    <button type="button" class="btn btn-dark" (click)="close(true)">
      <i-bs name="x-circle" class="me-2"></i-bs>Fermer</button>
  </div>
</div>
