import { AuthService } from 'auth';

import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ModalComponent } from '../../../../share/components/modal/modal.component';

@Component({
  selector: 'app-reset-pwd',
  templateUrl: './reset-pwd.component.html',
  styleUrls: ['../login-page/login-page.component.scss'],
})
export class ResetPwdComponent implements OnInit {
  resetPwdForm: FormGroup | undefined;
  userToken: string | null | undefined;
  showPassword = false;
  showConfirmPassword = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal
  ) {}

  get password() {
    return this.resetPwdForm?.get('password');
  }
  get confirmPassword() {
    return this.resetPwdForm?.get('confirmPassword');
  }
  ngOnInit() {
    this.userToken = this.activatedRoute.snapshot.paramMap.get('userToken');
    this.resetPwdForm = this.formBuilder.group({
      password: new FormControl(
        '',
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9@$!%*?&]).{12,}$')
      ),
      confirmPassword: new FormControl(''),
    });
  }

  onSubmit() {
    if (this.userToken) {
      this.authService
        .initializePassword(this.resetPwdForm?.value.password, this.userToken)
        .subscribe({
          next: async () => {
            localStorage.removeItem('token');
            const modalRef = this.modalService.open(ModalComponent);
            modalRef.componentInstance.type = 'success';
            modalRef.componentInstance.header = 'Réinitialisation mot de passe';
            modalRef.componentInstance.message =
              'Votre mot de passe a bien été réinitialisé. <br> Vous allez être redirigé vers la page de connexion.';
            await modalRef.result.then(() => this.router.navigate(['/']));
          },
          error: (err) => {
            const modalRef = this.modalService.open(ModalComponent);
            modalRef.componentInstance.type = 'danger';
            modalRef.componentInstance.header =
              'Erreur de réinitialisation du mot de passe';
            modalRef.componentInstance.message = err.error
              ? err.error.message
              : 'Il y a eu une erreur lors de la réinitialisation de votre mot de passe. <br> Veuillez réessayer ou contacter un administrateur. <br> Désolé pour la gêne occasionnée.';
          },
        });
    }
  }
}
