import { Buffer } from 'buffer';
import { BehaviorSubject, lastValueFrom, map, Observable, of, Subject, tap } from 'rxjs';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

export enum Droit {
  ADMIN_NAT = 1,
  ADMIN_REGION = 2,
  CELLULE_LOGISTIQUE = 3,
  UTILISATEUR_COP = 4,
  UTILISATEUR_CLIENT = 5,
  UTILISATEUR_SOUS_TRAITANT = 6,
  LECTURE_SEULE = 7,
}

export interface Login {
  apiKey: string;
  cops: Cop[];
  droitId: Droit;
  email: string;
  login: string;
  nom: string;
  prenom: string;
  token: string;
  img?: string;
  parametrages: string[];
  id: number;
  regionIds?: number[];
  keyUser?: boolean;
}

export interface Cop {
  nom: string;
  id: number;
}

interface WaveToken {
  iss: string; // should be "wave_server",
  sub: string; // uuid
  iat: number;
  exp: number;
  client: string; // should be "wave_front",
  login: string;
  method: string; // "sso"|"password"
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  cops$: BehaviorSubject<Cop[]> = new BehaviorSubject<Cop[]>([]);
  get cops() {
    return this.login?.cops ?? [];
  }
  get login() {
    return this._login;
  }
  get parametrages() {
    return this._login?.parametrages ?? [];
  }
  get loginFrom() {
    return localStorage.getItem('loginFrom') || undefined;
  }
  set loginFrom(loginFrom: string | undefined) {
    loginFrom = loginFrom?.replace(/^https?:\/\//, '/');
    if (loginFrom) {
      localStorage.setItem('loginFrom', loginFrom);
    } else {
      localStorage.removeItem('loginFrom');
    }
  }
  get token() {
    return localStorage.getItem('token') || undefined;
  }

  set token(token: string | undefined) {
    if (token) {
      localStorage.setItem('token', token);
    } else {
      localStorage.removeItem('token');
    }
  }

  private _login?: Login;

  constructor(private httpClient: HttpClient) { }

  // app is an application name, if provided it must be in parametrages returned from api/login/refreshUser
  public async initialize(app?: string): Promise<Login | undefined> {
    const token = localStorage.getItem('token');
    // if no token or any error
    if (app !== 'LOGIN') {
      // in case of redirection, keep current url in localStorage
      this.loginFrom = window.location.href;
    }
    if (token) {
      try {
        const waveToken: WaveToken = JSON.parse(
          Buffer.from(token.split('.')[1], 'base64').toString()
        );
        if (waveToken.exp > new Date().getTime() / 1000) {
          const login = await lastValueFrom(this.getUser(token));
          this.loginFrom = undefined; // no use to keep url, loggin succeed
          if (!app || app === 'LOGIN' || login.parametrages.includes(app)) {
            this.cops$.next(login.cops);
            this.updateLogin(login);
            return login;  // LOGIN is never in parametrages
          } else {
            // not allowed, go to login
          }
        } else {
          console.warn('token expired', token);
        }
      } catch (e) {
        console.error('error parsing token', e);
      }
    }
    if (app !== 'LOGIN') {
      window.location.href = '/'; // go to login
    }
    return undefined;
  }

  public ssoLogin(idToken: string): Observable<Login> {
    return this.httpClient
      .post<Login>('/api/login/sso', {
        idToken: idToken,
      })
      .pipe(
        tap((login) => {
          this.updateLogin(login);
        }),
      );
  }

  public passwordLogin(login: string, password: string): Observable<Login> {
    return this.httpClient
      .post<Login>('/api/login/form', {
        login,
        password,
      })
      .pipe(
        tap((login) => {
          this.updateLogin(login);
        }),
        map((login) => login)
      );
  }

  public resetPassword(email: string): Observable<boolean> {
    return this.httpClient
      .post<void>(
        `/api/login/requestInitializePwd`,
        { email },
        { observe: 'response' }
      )
      .pipe(
        map((response: HttpResponse<void>) => {
          return response.ok;
        })
      );
  }
  public initializePassword(
    password: string,
    token: string
  ): Observable<boolean> {
    const url = `/api/login/resetPwd`;
    return this.httpClient
      .post<void>(url, { password, token }, { observe: 'response' })
      .pipe(
        map((response: HttpResponse<void>) => {
          return response.ok;
        })
      );
  }
  private getUser(token: string): Observable<Login> {
    return this.httpClient.get<Login>('/api/login/refreshUser', {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    }).pipe(
      tap((login) => {
        login.cops = login.cops.sort((a, b) => a.nom.localeCompare(b.nom));
        this.cops$.next(login.cops);
      })
    );
  }

  private updateLogin(login: Login): void {
    localStorage.setItem('token', login.apiKey);
    this.cops$.next(login.cops);
    this._login = login;
  }

  public simulate(token: string): Observable<Login | undefined> {
    const adminToken = localStorage.getItem('token');
    const adminName = localStorage.getItem('user-name');
    const adminAvatar = localStorage.getItem('avatar');
    this.setAdminElements(adminToken, adminName, adminAvatar);

    localStorage.setItem('token', token);
    if (token) {
      try {
        const waveToken: WaveToken = JSON.parse(
          Buffer.from(token.split('.')[1], 'base64').toString()
        );
        if (waveToken.exp > new Date().getTime() / 1000) {
          return this.getUser(token).pipe(
            tap((login) => {
              if (login) {
                this.updateLogin(login);
                localStorage.setItem(
                  'user-name',
                  login.prenom + ' ' + login.nom
                );
              }
            })
          );
        } else {
          console.warn('token expired', token);
          return of(undefined);
        }
      } catch (e) {
        console.log(e);
        return of(undefined);
      }
    } else {
      return of(undefined);
    }
  }
  public setAdminElements(
    token: string | null,
    adminName: string | null,
    avatar: string | null
  ) {
    if (token) {
      localStorage.setItem('admin-token', token);
      localStorage.setItem('admin-name', adminName!);
      localStorage.setItem('admin-avatar', avatar!);
    } else {
      localStorage.removeItem('admin-token');
      localStorage.removeItem('admin-name');
      localStorage.removeItem('admin-avatar');
    }
  }
}
