import { NgxBootstrapIconsModule } from 'ngx-bootstrap-icons';

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lib-modal',
  templateUrl: './modal.component.html',
  standalone: true,
  imports: [NgxBootstrapIconsModule],
})
export class ModalComponent implements OnInit {
  @Input() type?: string;
  @Input() header?: string;
  @Input() message?: string;
  constructor(public activeModal: NgbActiveModal) {}
  ngOnInit() {
    this.activeModal.update({
      centered: true,
      backdropClass: 'opacity-50 bg-body',
    });
  }
  close(value: boolean) {
    this.activeModal.close(value);
  }
}
