 <div
  class="row align-items-center justify-content-center align-self-center m-2"
>
 <div class="card bg-white col-lg-4 col-12">
    <div class="card-body p-4 row justify-content-center">
      <div class="py-3 text-center title">
        <div class="logo">
          <p class="mt-1 mb-0 text-primary">WAVE</p>
        </div>
        <div class="d-flex justify-content-center">
          <div class="small text-start">
            Le mot de passe doit respecter les règles suivantes :
            <ul class="list-unstyled">
                <li>- faire au minimum 12 caractères</li>
                <li>- avoir une miniscule</li>
                <li>- avoir une majuscule</li>
                <li>- avoir un chiffre ou un caractère spécial</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-8 col-s-10 my-2">
          <div class="mt-2">
            <form [formGroup]="resetPwdForm!" (ngSubmit)="onSubmit()">
              <div class="input-group mb-3">
                <input type="{{ showPassword ? 'text' : 'password' }}"
                    class="form-control"
                    id="password"
                    formControlName="password"
                    autocomplete="password"
                    placeholder="Nouveau mot de passe">
                <div class="input-group-append my-auto btn border" (click)="showPassword=!showPassword">
                  @if (!showPassword) {
                    <i-bs name="eye-fill"></i-bs>
                  } @else {
                    <i-bs name="eye-slash-fill"></i-bs>
                  }
                </div>
                @if (!password?.valid) {
                  <div class="small text-danger w-100 mt-1" >
                    <p class="error"> Le mot de passe est invalide. </p>
                </div>
                }
              </div>
               <div class="input-group mb-3">
                <input type="{{ showConfirmPassword ? 'text' : 'password' }}"
                    class="form-control"
                    id="confirmPassword"
                    formControlName="confirmPassword"
                    autocomplete="confirmPassword"
                    placeholder="Confirmer le mot de passe">
                 <div class="input-group-append my-auto btn border" (click)="showConfirmPassword=!showConfirmPassword">
                  @if (!showConfirmPassword) {
                    <i-bs name="eye-fill"></i-bs> 
                  } @else {
                    <i-bs name="eye-slash-fill"></i-bs>
                  }
                 </div>
                 @if (confirmPassword?.value && password?.value !== confirmPassword?.value) {
                  <div class="small text-danger w-100 mt-1" >
                    <p class="error">Les deux mots de passe saisis ne sont pas identiques.</p>
                  </div>
                 }
              </div>
              <div class="text-center mt-3">
                 <button
                  type="submit"
                  id="validate"
                   class="validate btn w-100 py-2 btn-primary text-white"
                  [disabled]="! password?.value || password?.value !== confirmPassword?.value || !password?.valid">
                            Valider
                  </button>
              </div>
            </form>
          </div>
      </div>
    </div>
  </div>
</div>
