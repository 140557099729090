import { Component, Injectable, Input, TemplateRef } from '@angular/core';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';

import { Toast, ToastService } from '../../services/toast.service';

// NOTICE : a toast.service must be implemented in each project because lib.service can only be accessed by lib component (not client ones)
@Component({
  selector: 'lib-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  standalone: true,
  imports: [NgbToastModule],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class:
      'toast-container position-fixed bottom-0 start-50 translate-middle p-3',
  },
})
export class ToastComponent {
  @Input() toastService?: ToastService;

  remove(toast: Toast): void {
    this.toastService?.remove(toast);
  }
}
