import { Injectable, TemplateRef } from '@angular/core';

export interface Toast {
  text?: string;
  template?: TemplateRef<any>;
  classname: string;
  delay: number;
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  toasts: Toast[] = [];

  show(text: string, type: string) {
    this.toasts = [
      ...this.toasts,
      {
        text,
        classname: 'text-white ' + type,
        delay: 3000,
      },
    ];
  }

  showTemplate(template: TemplateRef<any>, type: string) {
    this.toasts = [
      ...this.toasts,
      {
        template,
        classname: 'text-white ' + type,
        delay: 3000,
      },
    ];
  }

  showError(message: string) {
    this.show(message, 'bg-danger');
  }

  showInfo(message: string) {
    this.show(message, 'bg-info');
  }

  showSuccess(message: string) {
    this.show(message, 'bg-success');
  }

  remove(toast: Toast) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }

  clear() {
    this.toasts = [];
  }
}
