<div
  class="row align-items-center justify-content-center align-self-center m-0 vh-100"
>
  <div class="card bg-white col-lg-4 col-12">
    <div class="card-body p-4 row justify-content-center">
      <div class="py-3 text-center title">
        <div class="logo">
          <p class="mt-2 mb-0 text-primary">WAVE</p>
        </div>
      </div>
      <div class="pt-3 text-center">
        <asl-google-signin-button
          class="d-flex justify-content-center cursor-pointer"
          type="standard"
          shape="pill"
          size="large"
          locale="fr_FR"
          (click)="loginWithSso()"
        ></asl-google-signin-button>
      </div>
      <div class="col-8 col-s-10 my-4">
        @if (!isPasswordForgotten) {
          <div>
            <div class="mt-3">
              <form [formGroup]="authForm" (ngSubmit)="onLogin()">
                <div class="input-group mb-3">
                  <input
                    type="text"
                    id="login"
                    class="form-control"
                    placeholder="login"
                    formControlName="login"
                    (ngModelChange)="resetError()"
                  />
                  @if (error === USER_NOT_FOUND) {
                    <div class="m-1 w-100 text-danger small">
                      Login introuvable.
                    </div>
                  }
                </div>
                <div class="input-group mb-3">
                  <input
                    type="{{inputType}}"
                    id="password"
                    class="form-control"
                    placeholder="******"
                    formControlName="password"
                    autocomplete="current-password"
                    (ngModelChange)="resetError()"
                  />
                  <div class="input-group-append my-auto btn border" (click)="togglePassword()">
                    @if (inputType === 'password') { 
                      <i-bs name="eye-fill"></i-bs>
                    } @else {
                      <i-bs name="eye-slash-fill"></i-bs>
                    }
                  </div>
                  @if (error === WRONG_PASSWORD) {
                    <div class="m-1 w-100 text-danger small">
                      Mot de passe incorrect.
                    </div>
                  }
                </div>
                <div class="text-center mt-3">
                  <button
                    id="login-button"
                    type="submit"
                    class="validate btn w-100 py-2 btn-primary text-white"
                    [disabled]="authForm.invalid"
                  >
                    Connexion
                  </button>
                </div>
                <div class="text-center mt-4 reset-password">
                  <button
                    id="show-reset-password"
                    class="btn btn-link"
                    (click)="passwordForgotten(true)"
                  >
                    Mot de passe oublié ?
                  </button>
                </div>
              </form>
            </div>
          </div>
        }
        @if (isPasswordForgotten) {
          <div>
            <form class="mt-3" [formGroup]="passwordForm">
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="email"
                  placeholder="email"
                  formControlName="email"
                  [class.border-danger]="passwordForm.invalid"
                  [class.border-success]="passwordForm.valid"
                />
              </div>
              <div class="text-center mt-3">
                <button
                  type="button"
                  id="reset-password"
                  class="validate btn w-100 py-2 btn-primary text-white"
                  (click)="resetPassword()"
                  [disabled]="passwordForm.invalid"
                >
                  Réinitialiser mot de passe
                </button>
                <button
                  type="button"
                  class="btn w-100 py-2 my-3 btn-info text-white"
                  (click)="passwordForgotten(false)"
                >
                  Annuler
                </button>
              </div>
            </form>
          </div>
        }
      </div>
    </div>
  </div>
  <lib-toast [toastService]="toastService"></lib-toast>
</div>
