<div class="loader">
  <div class="d-flex justify-content-center align-items-center h-100">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only"></span>
    </div>
    <div class="spinner-grow text-dark" role="status">
      <span class="sr-only"></span>
    </div>
  </div>
</div>
