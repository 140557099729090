import { catchError, throwError, timeout } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { version } from '../version';

export interface Log {
  action?: string;
  client?: string;
  event?: string;
  resolution?: string;
  source?: string;
  value?: string | number;
  duration?: number;
  userId?: number;
  page?: string;
  description?: string;
}

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  private readonly client: string;
  private readonly resolution: string;
  private readonly source: string;
  private readonly environment: string;

  constructor(private http: HttpClient) {
    this.client = window.navigator.userAgent;
    this.resolution = window.screen.width + 'x' + window.screen.height;
    this.source = 'wave-front';
    switch (window.location.hostname) {
      case 'wave-rec.recyclage.veolia.fr':
        this.environment = 'rec';
        break;
      case 'wave.recyclage.veolia.fr':
        this.environment = 'prd';
        break;
      default:
        this.environment = 'dev';
        break;
    }
  }
  /**
   * Logs the given body to the server.
   * /!\ you MUST ShareModule.forRoot() to use this method
   *
   * @param {Log} body - The log object to be sent to the server.
   * @return {void} Returns nothing.
   */
  public log(body: Log): void {
    const bodyLog = {
      ...body,
      client: this.client,
      resolution: this.resolution,
      source: this.source,
      version: version.semver,
      environment: this.environment,
      user: localStorage.getItem('login'),
    };

    const options = { headers: this.getHeaders() };
    if (options.headers.get('Authorization') == null) {
      return;
    }

    this.http
      .post('/api/log', bodyLog, options)
      .pipe(
        timeout(60000),
        catchError((err) => {
          return throwError(() => 'Method not implemented.' + err);
        })
      )
      .subscribe();
  }

  private getHeaders(): HttpHeaders {
    const token = localStorage.getItem('token');
    if (token) {
      return new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      });
    } else {
      return new HttpHeaders();
    }
  }
}
