import { AngularSvgIconModule } from 'angular-svg-icon';
import { AuthModule } from 'auth';
import {
  eyeFill, eyeSlashFill, NgxBootstrapIconsModule, star, starFill
} from 'ngx-bootstrap-icons';

import {
  GoogleLoginProvider, GoogleSigninButtonModule, SocialAuthServiceConfig, SocialLoginModule
} from '@abacritt/angularx-social-login';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { LoaderComponent } from '../../../share/components/loader/loader.component';
import { ModalComponent } from '../../../share/components/modal/modal.component';
import { ToastComponent } from '../../../share/components/toast/toast.component';
import { Environment } from '../../../share/services/environment.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { RedirectComponent } from './redirect/redirect.component';
import { ResetPwdComponent } from './reset-pwd/reset-pwd.component';

const icons = {
  star,
  starFill,
  eyeFill,
  eyeSlashFill,
};
const environment = new Environment();

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    LoginPageComponent,
    RedirectComponent,
    ResetPwdComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbDropdownModule,
    SocialLoginModule,
    ReactiveFormsModule,
    AuthModule,
    SocialLoginModule,
    NgxBootstrapIconsModule.pick(icons),
    AngularSvgIconModule.forRoot(),
    GoogleSigninButtonModule,
    ModalComponent,
    LoaderComponent,
    ToastComponent,
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: true,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.googleId, {
              oneTapEnabled: true,
            }),
          },
        ],
        onError: (err) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule { }
