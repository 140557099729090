@if (ready()) {
<div class="container mt-4">
    <nav class="navbar navbar-white bg-white rounded">
        <div class="container-fluid">
            <span class="navbar-brand mb-0 h1"
                ><img
                    src="assets/img/veolia.png"
                    alt="menu"
                    width="45"
                    height="45"
                    class="mr-2"
            /></span>
            <div class="d-flex mt-1">
                <div class="dropdown" ngbDropdown>
                    <button
                        class="btn btn-white dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        ngbDropdownToggle
                    >
                        {{ user?.prenom | titlecase }}
                        {{ user?.nom | titlecase }}
                    </button>
                    <ul
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                        ngbDropdownMenu
                    >
                        <li ngbDropdownItem>
                            <a class="dropdown-item" href="#" (click)="logout()"
                                >Déconnexion</a
                            >
                        </li>
                    </ul>
                </div>
                @if (adminToken) {
                <button
                    class="btn btn-outline-danger ms-2"
                    type="button"
                    (click)="logoutSimulation()"
                >
                    Quitter la simulation
                </button>
                }
            </div>
        </div>
    </nav>

    <div
        id="main"
        class="mt-3"
        style="box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15)"
    >
        <div class="text-center">
            @if (adminToken && droitId === UTILISATEUR_CLIENT) {
            <p>
                Pour accéder en tant que client, veuillez privilégier
                l'utilisation d'une cette application :
                <a [href]="waveClientUrl">{{ waveClientUrl }} </a>.
            </p>
            } @if (adminToken && droitId === UTILISATEUR_SOUS_TRAITANT) {
            <p>
                En tant que sous traitant, vous n’avez pas accès à cette
                plateforme pour le moment
            </p>
            }
        </div>
        <div class="row p-2 m-3 justify-content-center text-white">
            @for (menu of userMenus; track menu) {
            <div class="col-lg-4 col-xl-4 col-md-6 col-12 my-2">
                <div
                    class="card cursor-pointer text-center bg-{{
                        menu.color
                    }} mx-1"
                >
                    <div
                        class="btn border-0 position-absolute start-0 top-0 favorite text-white"
                    >
                        @if (menu.favorite) {
                        <i-bs
                            name="star-fill"
                            width="2rem"
                            height="2rem"
                            (click)="changeFavorite(menu, false)"
                        ></i-bs>
                        } @else {
                        <i-bs
                            width="2rem"
                            height="2rem"
                            name="star"
                            (click)="changeFavorite(menu, true)"
                        ></i-bs>
                        }
                    </div>
                    <div
                        class="card-body d-grid"
                        (click)="navigateTo(menu.path)"
                    >
                        <div class="card-img">
                            <svg-icon
                                src="../assets/{{ menu.icon }}"
                            ></svg-icon>
                        </div>
                        <div class="title text-white w-100 my-auto h-100">
                            {{ menu.name }}
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    </div>
</div>
} @else {
<lib-loader id="loadInProgress"></lib-loader>
}
