import { Component } from '@angular/core';

import { Environment } from '../../../share/services/environment.service';
import { ToastService } from '../../../share/services/toast.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  public readonly version: string;

  constructor(public toastService: ToastService, environments: Environment) {
    this.version = environments.version;
  }
}
