import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../components/modal/modal.component';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  private message: string = '';
  private header: string = 'Erreur serveur';

  constructor(private modalService: NgbModal) {}

  public async showError(error: HttpErrorResponse) {
    this.message = error.error ? error.error.message : error.message;
    switch (error.status) {
      case 401:
        this.header = 'Accès manquant';
        this.message = "Vous n'êtes pas authorisé à accéder à cette page";
        break;
      case 403:
        this.header = 'Utilisateur désactivé';
        break;
      case 500:
        this.message =
          'Un problème est survenu, veuillez rafraichir votre page ou contacter un administrateur. <br> Excusez-nous pour la gêne occasionnée.';
        break;
      case 504:
        this.message =
          'Le serveur est momentanément indisponible, veuillez rafraichir votre page ou attendre quelques minutes. <br> Excusez-nous pour la gêne occasionnée.';
        break;
    }
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.type = 'danger';
    modalRef.componentInstance.header = this.header;
    modalRef.componentInstance.message = this.message;
    await modalRef.result.then(() => {
      if (error.status === 403) {
        this.logout();
      }
    });
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('avatar');
    window.location.href = '/';
  }
}
