@if (toastService) {
    @for (toast of toastService.toasts; track toast) {
      <ngb-toast
      [class]="toast.classname"
      [autohide]="true"
      [delay]="toast.delay || 5000"
      (hidden)="remove(toast)"
    >
      <ng-template [ngIf]="toast.template" [ngIfElse]="text">
        <ng-template [ngTemplateOutlet]="toast.template!"></ng-template>
      </ng-template>
  
      <ng-template #text>{{ toast.text }}</ng-template>
    </ngb-toast>
    }
}
