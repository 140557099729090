import { catchError, EMPTY, Observable, throwError } from 'rxjs';

import {
  HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) { }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const authToken = this.auth.token;
    if (authToken) {
      request = request.clone({
        setHeaders: { Authorization: 'Bearer ' + authToken },
      });
    }

    return next.handle(request).pipe(
      catchError((err) => {
        if (
          err instanceof HttpErrorResponse &&
          (err as HttpErrorResponse).status === 401
        ) {
          console.error('unauthorized error ');
          window.location.href = '/';
          return EMPTY;
        } else {
          return throwError(() => err);
        }
      })
    );
  }
}
