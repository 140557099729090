import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-redirect',
  templateUrl: 'redirect.component.html',
})
export class RedirectComponent implements OnInit {
  constructor(private activatedRoute: ActivatedRoute) {}
  ngOnInit() {
    this.activatedRoute.queryParams.pipe(first()).subscribe((params) => {
      const token = params['token'];
      const route = params['module'] ?? 'accueil';
      localStorage.setItem('token', token);
      window.location.href = `/${route}/`;
    });
  }
}
