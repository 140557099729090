import { AuthService, Droit, Login } from 'auth';

import { Component, OnInit, signal } from '@angular/core';
import { Router } from '@angular/router';

import { Environment } from '../../../../share/services/environment.service';
import { ErrorService } from '../../../../share/services/error.service';
import { LoggerService } from '../../../../share/services/logger.service';
import { ToastService } from '../../../../share/services/toast.service';

interface Menu {
  name: string;
  path: string;
  app: string;
  favorite: boolean;
  color: string;
  icon: string;
}
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit {
  public parametres: string[] = [];
  public user?: Login;
  public userMenus: Menu[] = [];
  public version: string;
  public ready = signal(false);
  UTILISATEUR_CLIENT = Droit.UTILISATEUR_CLIENT;
  UTILISATEUR_SOUS_TRAITANT = Droit.UTILISATEUR_SOUS_TRAITANT;

  public menus: Menu[] = [
    {
      name: 'City',
      path: 'city',
      app: 'CITY',
      favorite: false,
      color: 'dark',
      icon: 'city.svg',
    },
    {
      name: 'PAP',
      path: 'pap',
      app: 'PAP',
      favorite: false,
      color: 'coral',
      icon: 'worker.svg',
    },
    {
      name: 'Planning personnel',
      path: 'planning-personnel',
      app: 'PLANNING-PERSONNEL',
      favorite: false,
      color: 'bleuet',
      icon: 'personnel.svg',
    },
    {
      name: 'Suivi Parc',
      path: 'parc',
      app: 'WAVE-PARC',
      favorite: false,
      color: 'maya',
      icon: 'parc.svg',
    },
    {
      name: 'Chat',
      path: 'chat',
      app: 'WAVE-CHAT',
      favorite: false,
      color: 'ocean',
      icon: 'envelope.svg',
    },
    {
      name: 'Lixo',
      path: 'lixo',
      app: 'LIXO',
      favorite: false,
      color: 'teal',
      icon: 'lixo.svg',
    },
    {
      name: 'Référentiels',
      path: 'referentiel',
      app: 'REFERENTIEL',
      favorite: false,
      color: 'maroon',
      icon: 'referentiel.svg',
    },
    {
      name: 'Support',
      path: 'support',
      app: 'SUPPORT',
      favorite: false,
      color: 'orange',
      icon: 'support.svg',
    },
  ];
  adminToken: string | null = null;
  private favorite: string | null = null;
  constructor(
    public toastService: ToastService,
    public auth: AuthService,
    private router: Router,
    private errorService: ErrorService,
    private loggerService: LoggerService,
    private environments: Environment
  ) {
    this.version = this.environments.version;
  }
  droitId: number | undefined;
  waveClientUrl = this.environments.waveClientUrl;
  async ngOnInit(): Promise<void> {
    this.adminToken = localStorage?.getItem('admin-token');
    const login = await this.auth.initialize('LOGIN');
    this.favorite = localStorage.getItem(login?.login + '-favorite');
    this.droitId = login?.droitId;
    this.parametres = login?.parametrages ?? [];
    this.userMenus = this.menus.filter((m) =>
      login?.parametrages?.includes(m.app)
    );
    if (this.favorite) {
      this.userMenus = this.userMenus.map((m) => ({
        ...m,
        favorite: m.path === this.favorite,
      }));
    }
    if (this.userMenus.length === 1) {
      window.location.href = this.userMenus[0].path;
    }
    this.user = login;
    this.ready.set(true);
  }

  logout() {
    this.loggerService.log({
      event: 'logout',
      page: 'login_dashboard',
      description:
        "L'utilisateur: " + localStorage.getItem('login') + ' se déconnecte',
    });
    localStorage.removeItem('token');
    localStorage.removeItem('avatar');
    window.location.href = '/';
  }

  navigateTo(link: string) {
    this.router.ngOnDestroy();
    window.location.href = `${link}/`;
  }

  changeFavorite(
    menu: {
      name: string;
      path: string;
      app: string;
      favorite: boolean;
    },
    select: boolean
  ) {
    this.toastService.showSuccess('Favoris modifié !');
    if (select) {
      localStorage.setItem(this.user?.login + '-favorite', menu.path);
      this.favorite = menu.path;
      this.userMenus.forEach((m) => (m.favorite = m.app === menu.app));
      this.loggerService.log({
        event: 'add-favorite',
        page: 'login_dashboard',
        description: 'Ajouter un module:' + menu.name + ' au favoris',
      });
    } else {
      this.loggerService.log({
        event: 'remove-favorite',
        page: 'login_dashboard',
        description: 'Enlever un module:' + menu.name + ' au favoris',
      });
      this.userMenus.forEach((m) => (m.favorite = false));
      localStorage.removeItem(this.user?.login + '-favorite');
    }
  }
  logoutSimulation() {
    if (this.adminToken) {
      localStorage.setItem('token', this.adminToken);
      localStorage.removeItem('admin-token');
      const userName = localStorage.getItem('admin-name');
      if (userName) {
        localStorage.setItem('user-name', userName);
        localStorage.removeItem('admin-name');
      }
      const avatar = localStorage.getItem('admin-avatar');
      if (avatar) {
        localStorage.setItem('avatar', avatar);
        localStorage.removeItem('admin-avatar');
      }
      this.loggerService.log({
        event: 'close-simulation',
        page: 'login_dashboard',
        description: 'Quitter la simulation',
      });
    }
    window.location.href = '/accueil';
  }
}
