import { Injectable } from '@angular/core';

import { version } from '../version';

@Injectable({
  providedIn: 'root',
})
export class Environment {
  public googleId: string;
  public version: string;
  public waveHubUrl: string;
  public waveClientUrl: string;
  public googleApiKey: string;

  /*
    Il n'est pas possible d'utiliser fileReplacements dans une lib pour avoir des fichiers environment.rec.ts et environment.prd.ts
    https://github.com/nrwl/nx/issues/7444
    La solution proposée dans https://github.com/nrwl/nx/pull/7456 consiste a trouver l'environnement courant en fonction de l'URL
    Pas con, même si c'est pas super bien implémenté ici :-p
  */

  constructor() {
    this.googleApiKey = 'AIzaSyAM2S4sGWSIM7qDnSJypB6MswE7C9r3x0Y'; // ionic 'AIzaSyAM2S4sGWSIM7qDnSJypB6MswE7C9r3x0Y'; //'AIzaSyBWXo24fsdhQ5hO1T7-A6SoVYUJg2iEtBU'; // originale MAPTRACK_HORS_PROD 'AIzaSyAM2S4sGWSIM7qDnSJypB6MswE7C9r3x0Y';
    this.version = version.semver;
    if (window.location.host.startsWith('localhost')) {
      this.googleId =
        '885433908851-4dm9hsvqd6u256tj08g6pecrquuq5dld.apps.googleusercontent.com';
      this.waveHubUrl = 'https://wave-rec.recyclage.veolia.fr';
      this.waveClientUrl = 'https://wave-client-rec.recyclage.veolia.fr';
    } else if (window.location.host.startsWith('wave-rec')) {
      this.googleId =
        '885433908851-4dm9hsvqd6u256tj08g6pecrquuq5dld.apps.googleusercontent.com';
      this.waveHubUrl = 'https://wave-rec.recyclage.veolia.fr';
      this.waveClientUrl = 'https://wave-client-rec.recyclage.veolia.fr';
    } else {
      this.googleId =
        '885433908851-4dm9hsvqd6u256tj08g6pecrquuq5dld.apps.googleusercontent.com';
      this.waveHubUrl = 'https://wave.recyclage.veolia.fr';
      this.waveClientUrl = 'https://wave-client.recyclage.veolia.fr';
    }
  }
}
